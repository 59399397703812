import { classNames } from '@common/application/utils/classnames';
import { encode } from '@common/application/utils/seo';
import { ServerSide } from '@common/application/utils/server-side';
import { Button } from '@common/components/common/button';
import { DImage } from '@common/components/common/image';
import { ROUTE_MAP } from '@common/constants/route-map';
import type { GetServerSidePropsContext } from 'next';
import Head from 'next/head';
import { useTranslation } from 'next-i18next';
import { useEffect } from 'react';

import { IMG_ICON_NOT_FOUND } from '@/resources/images';

const NotFount = () => {
  const { t } = useTranslation(['common']);
  const title = t('pageNotFound.title');

  useEffect(() => {
    window.location.href = ROUTE_MAP.HOME;
  }, []);
  return (
    <>
      <Head>
        <title>
          {t('common:pageTitle.error', {
            error: encode(title)
          })}
        </title>
      </Head>
      <div className="min-h-screen bg-white">
        <div className="h-100 flex items-center flex-col">
          <DImage src={IMG_ICON_NOT_FOUND} className="w-75 min-h-42.5 h-fit mt-27" />
          <p className={classNames('text-black-light text-sm mt-6')}>{t(title)}</p>
          <Button
            className="min-w-22 w-fit px-4 h-9 mt-6 !bg-primary text-white"
            onClick={() => {
              window.location.href = ROUTE_MAP.HOME;
            }}
          >
            <span className={classNames('uppercase', 'text-xs', 'xl:text-base')}>
              {t('pageNotFound.backToHome')}
            </span>
          </Button>
        </div>
      </div>
    </>
  );
};

export default NotFount;

export async function getStaticProps(ctx: GetServerSidePropsContext) {
  const i18nProps = await ServerSide.getTranslations(ctx, ['common']);
  return {
    props: {
      ...i18nProps
    },
    revalidate: 60
  };
}
